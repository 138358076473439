(() => {
    const fileInputs = document.querySelectorAll('.file-input');

    if (fileInputs.length < 1) {
        return;
    }

    [...fileInputs].forEach(el => {
        el.addEventListener('change', () => {
            let fileName = el.value;
            let parent = el.parentElement;
            let removeFile = parent.nextElementSibling;
            let label = parent.querySelector('.file-input-name');

            parent.classList.toggle('file-input-attached');
            removeFile.classList.toggle('hidden');
            label.innerHTML = fileName.replace('C:\\fakepath\\', ' ');

            removeFile.addEventListener('click', () => {
                el.value = null;
                label.innerHTML = label.dataset.label;
                parent.classList.toggle('file-input-attached');
                removeFile.classList.toggle('hidden');
            }, { once: true });
        });
    });
})();
