(() => {
    const header = document.querySelector('.fixed-header');

    window.addEventListener('scroll', () => {
        if (window.scrollY > 100) {
            header.classList.add('bg-header');
        } else {
            header.classList.remove('bg-header');
        }
    });
})();
