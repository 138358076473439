import { toggleLoadingIndicator } from './loading-indicator';
import { displayErrors } from './form-errors';

const axios = require('axios');

(function() {
    const contactForm = document.querySelector('[data-contact-form]');
    if (!contactForm) {
        return;
    }

    contactForm.addEventListener('submit', e => {
        e.preventDefault();

        toggleLoadingIndicator(contactForm);

        axios.post(contactForm.getAttribute('action'), {
            subject: contactForm.querySelector('[name=subject]').value,
            name: contactForm.querySelector('[name=name]').value,
            email: contactForm.querySelector('[name=email]').value,
            phone: contactForm.querySelector('[name=phone]').value,
            company: contactForm.querySelector('[name=company]').value,
            message: contactForm.querySelector('[name=message]').value,
        })
        .then(response => {
            toggleLoadingIndicator(contactForm);
            window.location.href = '/thankyou';
        })
        .catch(error => {
            toggleLoadingIndicator(contactForm);
            displayErrors(contactForm, error.response.data);
        });
    });
})();
