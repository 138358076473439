import Glide, { Controls, Breakpoints, Swipe } from '@glidejs/glide/dist/glide.modular.esm'

const teamCarousels = document.querySelectorAll('.team-carousel');
const careersCarousel = document.querySelector('.careers-carousel');
const workCarousel = document.querySelector('.work-carousel');

if (teamCarousels) {
    let teamSliders = [];

    [...teamCarousels].forEach((teamCarousel, i) => {
        const glide = teamCarousel.querySelector('.glide');

        teamSliders[i] = new Glide(glide, {
            type: 'carousel',
            startAt: 0,
            perView: 3,
            gap: 0,
            rewind: true,
            navigation: true,
            breakpoints: {
                576: {
                    perView: 1
                },
                992: {
                    perView: 2
                },
                1200: {
                    perView: 3
                }
            }
        }).mount({Controls, Breakpoints, Swipe});

        glideArrows(teamSliders[i]);
    });
}

if (careersCarousel) {
    const glide = careersCarousel.querySelector('.glide');

    const careersSlider = new Glide(glide, {
        type: 'carousel',
        startAt: 0,
        perView: 2,
        gap: 0,
        rewind: true,
        breakpoints: {
            768: {
                perView: 1
            },
        }
    }).mount({Controls, Breakpoints, Swipe});

    glideArrows(careersSlider);
}

if (workCarousel) {
    const glide = workCarousel.querySelector('.glide');

    const workSlider = new Glide(glide, {
        type: 'carousel',
        startAt: 0,
        perView: 1,
        gap: 0,
        rewind: true,
        navigation: true
    }).mount({Controls, Swipe});
}

function glideArrows(glide) {
    const leftArrows = document.querySelectorAll('.carousel-left-arrow');
    const rightArrows = document.querySelectorAll('.carousel-right-arrow');

    [...leftArrows].forEach(leftArrow => {
        leftArrow.addEventListener('click', function () {
            glide.go('<');
        });
    });

    [...rightArrows].forEach(rightArrow => {
        rightArrow.addEventListener('click', function () {
            glide.go('>');
        });
    });
}
