import { toggleLoadingIndicator } from './loading-indicator';
import { displayErrors } from './form-errors';

const axios = require('axios');

(function() {
    const jobForm = document.querySelector('[data-job-form]');
    if (!jobForm) {
        return;
    }

    jobForm.addEventListener('submit', e => {
        e.preventDefault();

        toggleLoadingIndicator(jobForm);

        let data = new FormData();
        data.append('subject', jobForm.querySelector('[name=subject]').value);
        data.append('role', jobForm.querySelector('[name=role]').value);
        data.append('first_name', jobForm.querySelector('[name=first_name]').value);
        data.append('last_name', jobForm.querySelector('[name=last_name]').value);
        data.append('email', jobForm.querySelector('[name=email]').value);
        data.append('phone', jobForm.querySelector('[name=phone]').value)

        let cv = jobForm.querySelector('[name=cv]').files;
        if (cv.length > 0) {
            data.append('cv', cv[0]);
        }

        let ml = jobForm.querySelector('[name=motivational_letter]').files;
        if (ml.length > 0) {
            data.append('motivational_letter', ml[0]);
        }

        const settings = { headers: { 'content-type': 'multipart/form-data' } };

        axios.post(jobForm.getAttribute('action'), data, settings)
        .then(response => {
            toggleLoadingIndicator(jobForm);
            window.location.href = '/thankyou';
        })
        .catch(error => {
            toggleLoadingIndicator(jobForm);
            displayErrors(jobForm, error.response.data);
        });
    });
})();
