function displayErrors(wrapper, errors) {
    Object.keys(errors).forEach((key, index) => {
        let inputField = wrapper.querySelector(`[name=${key}]`);
        let errorContainer = inputField.nextElementSibling;
        errorContainer.classList.remove('hidden');
        errorContainer.querySelector('.invalid-feedback').innerHTML = errors[key][0];

        errorContainer.addEventListener('click', () => {
            errorContainer.classList.add('hidden');
            inputField.focus();
        }, { once: true });
    });
}

export {
    displayErrors
}
