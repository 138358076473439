const modalToggles = document.querySelectorAll('.modal-toggle');

[...modalToggles].forEach(el => {
    el.addEventListener('click', () => {
        const id = el.dataset.modal;
        const modal = document.getElementById(id);

        document.querySelector('body').classList.toggle('overflow-y-hidden');
        modal.classList.toggle('active');
    });
});
